import React from "react";

function Contact() {
    return (
        <div className="font-mono text-white">
            <h1>Contact Me</h1>
            <p>How to reach me...</p>
        </div>
    );
}

export default Contact;
