import React from "react";

function Skills() {
    return (
        <div className="font-mono text-white">
            <h1>My Skills</h1>
            <ul>{/* List skills here */}</ul>
        </div>
    );
}

export default Skills;
