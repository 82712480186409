import React from "react";

function Art() {
    return (
        <div className="font-mono text-white">
            <h1>My Artwork</h1>
            <div>{/* Display your art here */}</div>
        </div>
    );
}

export default Art;
