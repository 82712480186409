import React from "react";

function Education() {
    return (
        <div className="font-mono text-white">
            <h1>My Educational History</h1>
            <ul>{/* List your educational history here */}</ul>
        </div>
    );
}

export default Education;
